






import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";         
@Component({
   
})
export default class RisorsePageDetails extends Vue {
   
}
